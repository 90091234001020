import React from "react"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"

import SmartFeedFiltersComponent from "../components/SmartFeedWithFilters"

require("es6-promise").polyfill()

export default function HomepagePagePage({
  pageContext: { integratorData, contentMetaData, contentDataData },
  props,
}) {
  return (
    <Layout {...props}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{contentMetaData.seoTitle}</title>
        <meta name="description" content={contentMetaData.metaDescription} />
      </Helmet>

      <div className="main-container">
        <div class="hero hero-black">
          <div class="container">
            <div class="holder">
              <div class="wrap-in">
                <div class="row">
                  <div class="col-lg-5 col-xl-4">
                    <div class="text-block">
                      <h1>{contentDataData[0].children[0].value}</h1>
                      <p>{contentDataData[0].children[1].value}</p>
                    </div>
                  </div>
                </div>
              </div>
              <span class="triangle"></span>
            </div>
          </div>
          <figure class="img">
            <div class="img-hold ">
              <img
                width="1240"
                height="460"
                src={contentDataData[0].children[2].value}
                class="attachment-hero-bg size-hero-bg ls-is-cached lazyloaded"
                alt=""
                loading="lazy"
              />
            </div>
          </figure>
        </div>

        <div className="container">
          <div className="client-wrapper">
            <SmartFeedFiltersComponent
              setVacancyLoading={false}
              apiKey={"AqaB0LzO0PQrvOYmC1ZV-akAYkEL6uKn60asb3gOGRc"}
              group={false}
              gdprKey=""
              CompanyGroupName="Activate Learning"
              VacancyPageBaseURL="https://activatelearningcareers.co.uk/vacancies/vacancy/"
              ReturnToSearchURL="https://activatelearningcareers.co.uk/vacancies/"
            />
            {/* <div className="main-section">
            </div>

            <div className="inner-content grid-section box-theme-2 joblist-section" style="background-color:rgb(255, 255, 255);">
            </div> */}
          </div>
        </div>
      </div>
    </Layout>
  )
}
